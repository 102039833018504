<template>
	<div class="session-wrapper mt-4">
		<div class="session-center text-xs-center mt-4" >
			<div class="session-content mt-4">
				
					<v-container fill-height grid-list-md text-xs-center>
            <v-layout row wrap row-eq-height align-center>
              <v-flex xs4></v-flex>
						  <v-flex xs4>
                  <img :src="appLogo" class="img-responsive mt-4 mb-3" width="300px" height="300px" />
    
                  <h2 class="primary--text pricing-title mb-3">{{$t('message.signUp')}}</h2>
                  <p class="fs-14">{{$t('message.havingAnAccount')}}
                    <router-link to="/session/login">{{$t('message.login')}}</router-link>
                  </p>

                  <v-form v-model="valid" class="mb-4" v-if="step == 1">
                    <v-text-field label="Enter Your Mobile or Email" v-model="contact" required></v-text-field>
                    <v-btn large @click="click_step1" block color="primary" class="mb-3">{{$t('message.next')}}</v-btn>
                    <p v-if="errorMessage != ''"> {{errorMessage}}</p>
                  </v-form>

                  <v-form v-model="valid" class="mb-4" v-if="step == 2">
                    <v-text-field label="Enter OTP" v-model="otp" required></v-text-field>
                    <v-btn large @click="click_step2" block color="primary" class="mb-3">{{$t('message.next')}}</v-btn>

                    <h1 class="mb-0">{{ totalMinutes }} : {{ totalSeconds < 10 ? `0${totalSeconds}`: totalSeconds }}</h1>
                  </v-form>

                  <v-form v-model="valid" class="mb-4" v-if="step == 3">
                    <v-text-field label="Enter Full Name" v-model="name" required></v-text-field>
                    <v-text-field label="Password" v-model="password1" required></v-text-field>
                    <v-text-field label="Confirm Password" v-model="password2" required></v-text-field>
                    <v-btn large @click="quickSignUp" block color="primary" class="mb-3">{{$t('message.register')}}</v-btn>
                  </v-form>

              </v-flex>
              <v-flex xs4></v-flex>
					  </v-layout>
				</v-container>
			</div>
		</div>
	</div>
</template>

<script>
import SessionSliderWidget from "Components/Widgets/SessionSlider";
import AppConfig from "Constants/AppConfig";

import axios from 'axios'
import {apiUserSignUp,apiUserValidateOtp,apiQuickSignUp} from '@/components/apiurls'
import {mapGetters,mapActions} from 'vuex'

export default {
  props: ["totalTime"],
  components: {
    SessionSliderWidget
  },
  data() {
    return {
      valid: false,
      step: 1,
      start: false,
      start1: false,
      otp: '',
      contact: '',
      show: true,
      password1: '',
      password2:'',
      name:'',
      errorMessage:'',
      
      passwordRules: [v => !!v || "Password is required"],
      appLogo: AppConfig.appLogo2,
      brand: AppConfig.brand,
      //TIMER
      time: {},
      seconds: this.totalTime ? this.totalTime : 5,
      timer: 0
    };
  },
  mounted() {
    let timeLeftVar = this.secondsToTime(this.seconds);
    this.time = timeLeftVar;
    this.startTimer();
  },
  computed: {
    hours() {
      return this.time.h;
    },
    totalMinutes() {
      return this.time.m;
    },
    totalSeconds() {
      return this.time.s;
    }
  },
  methods: {
    click_step1()
    {
      var post = {
      contact: this.contact,
      organization_id:2
      }
      axios.post(apiUserSignUp, post).then(response =>
      {
        var r = response.data
        if (r.code == 1)
        {
          this.step = 2
          this.seconds = 5
          this.startTimer()
          alert(this.seconds)
          this.start = true
        }
        else
        {
          this.errorMessage = r.msg
          this.$refs.staticModal.open()
        }
      })
    },
    click_step2()
    {
      if (this.totalSeconds == 0)
      {
        this.step = 1
        this.errorMessage = 'Your OTP is expired, please enter your OTP with in 60 seconds.'
      } else {
        this.start = false
        var post = {
          contact: this.contact,
          otp: this.otp,
          organization_id:1
        }
        axios.post(apiUserValidateOtp, post)
        .then(response =>
        {
          var r = response.data
          if (r.code == 1)
          {
            this.step = 3
          }
          else
          {
            this.errorMessage = r.msg
            this.$refs.staticModal.open()
          }
        })
      }
    },
    quickSignUp()
    {
      var post = 
      {
        contact:this.contact,
        otp:this.otp,
        name:this.name,
        password1:this.password1,
        password2:this.password2,
        organization_id:1,
      }
      axios.post(apiQuickSignUp, post)
      .then(response =>
      {
        var r = response.data
        if (r.code == 1)
        {
          localStorage.clear()
          localStorage.access_token = r.data.access_token
          this.$router.push({name: 'dashboard'})
        }
        else
        {
          this.errorMessage = r.msg
          this.$refs.staticModal.open()
        }
      })
    },
    //TIMER
    secondsToTime(secs) {
      let hours = Math.floor(secs / (60 * 60));

      let divisor_for_minutes = secs % (60 * 60);
      let minutes = Math.floor(divisor_for_minutes / 60);

      let divisor_for_seconds = divisor_for_minutes % 60;
      let seconds = Math.ceil(divisor_for_seconds);

      let obj = {
        h: hours,
        m: minutes,
        s: seconds
      };
      return obj;
    },
    startTimer() {
      if (this.timer === 0) {
        this.timer = setInterval(() => {
          this.countDown();
        }, 1000);
      }
    },
    countDown() {
      // Remove one second, and call secondsToTime method
      let seconds = this.seconds - 1;
      this.time = this.secondsToTime(seconds);
      this.seconds = seconds;
      // Check if we're at zero.
      if (seconds == 0) {
        clearInterval(this.timer);
      }
    },
    destroyed() {
      clearInterval(this.timer);
    }
  }
};
</script>
